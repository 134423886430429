import * as React from 'react'
import { Translation } from '../types/main'
import { translations } from '../translations'
import IndexContainer from '../containers/index'
import Seo from '../components/seo'

const t: Translation = {
  language: 'en',
  translations: translations.en
}

const IndexPage = () => (
  <>
    <Seo title="Flowby - Cut your next line" lang="en" />
    <IndexContainer t={t} />
  </>
)

export default IndexPage
